.login_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.login_title {
  font-size: 45px !important;
}
.ant-checkbox-inner::after {
  width: 5.714286px;
  height: 15.142857px;
  top: 44%;
}
.ant-checkbox-inner {
  width: 24px;
  height: 24px;
}
.ant-input-affix-wrapper-lg {
  height: 56px;
  font-size: 23px;
}
.ant-input-lg {
  font-size: 23px !important;
}
.ant-form-item-control-input-content {
  justify-content: center !important;
}
