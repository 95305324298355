.ant-list-item-meta-title {
  font-size: 23px;
}
.anticon-check-circle {
  font-size: 23px !important;
  margin-right: 8px;
}
.anticon-close-circle {
  font-size: 23px !important;
  margin-right: 8px;
}
.ant-select-selection-item {
  text-align: center;
  font-size: 23px;
  padding: 8px 16px 18px 0 !important;
}
.ant-select-arrow {
  top: 43%;
  right: 8px;
  width: 23px;
  height: 23px;
  font-size: 23px;
}
.select-option {
  font-size: 23px;
  text-align: center;
}
.ant-select-selector {
  height: 46px !important;
}
.ant-list-item {
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-form-item-explain-error {
  font-size: 18px !important;
}
.ant-message-notice {
  font-size: 23px;
}
