.ant-btn-lg {
  height: 68px !important;
  font-size: 23px !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled_scroll {
  -moz-appearance: textfield;
}
.disabled_scroll::-webkit-inner-spin-button {
  display: none;
}
.main_section {
  margin-top: 14vh;
  min-height: 86vh;
  height: 100%;
}
.amount-count {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ant-badge-count {
}
.ant-input[disabled] {
  font-weight: 700;
  color: black;
}
.ant-form-item-control-input-content {
  align-items: flex-start !important;
}
.ant-form-item-label > label {
  font-size: 23px;
}
.ant-layout-sider {
  flex: 0 0 18rem !important;
  max-width: 18rem !important;
  min-width: 18rem !important;
  width: 18rem !important;
}
.main_content {
  row-gap: 0px;
  overflow-y: scroll;
  min-height: 86vh;
}
.select {
  width: 70px;
}
.product_price {
  font-size: 23px;
  margin-right: 20px;
}

.ant-badge {
  color: hsla(0, 0%, 100%, 0.65) !important;
}
.product_count {
  font-size: 23px;
  margin-right: 10px;
}
.product_total {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: end;
}
.user-info-description {
  font-size: 23px !important;
  flex-direction: column;
}
.city_container {
  height: 52px;
  display: flex;
  justify-content: center;
}
.city {
  font-size: 23px;
  font-weight: 700;
}
.ant-layout-header {
  background-color: #0200ac;
}
.ant-menu.ant-menu-dark {
  background-color: #0200ac;
}
.ant-layout-sider {
  background-color: #0200ac;
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 20px !important;
  text-align: center;
  max-width: 100%;
}
.product-input-number {
  width: 240px;
}
@media screen and (max-width: 1399px) {
  .item_span {
    font-size: 22px;
  }
  .item_span_img {
    font-size: 22px !important;
  }
  .container_menu {
    height: 9.1vh;
  }
  .header {
    height: 9.1vh;
  }
  .item {
    height: 9.1vh;
  }
  .menu {
    height: 9.1vh;
  }
  .logo {
    height: 50px;
    width: 108px;
    background-size: 107px 50px;
  }
  .main_section {
    margin-top: 9.1vh;
    min-height: 90.9vh;
  }
  .sidebar_item {
    font-size: 22px !important;
  }
  .ant-layout-sider {
    flex: 0 0 15rem !important;
    max-width: 15rem !important;
    min-width: 15rem !important;
    width: 15rem !important;
  }
  .main_content {
    min-height: 90.9vh;
  }
  .ant-list-item-meta-title {
    font-size: 18px !important;
  }

  .product_price {
    font-size: 18px;
    margin-right: 20px;
  }
  .product_count {
    font-size: 18px;
    margin-right: 10px;
  }
  .select-option {
    font-size: 18px !important;
  }
  .ant-select-selection-item {
    font-size: 18px !important;
    padding: 3px 9px 0 0 !important;
  }
  .ant-select-selector {
    height: 37px !important;
  }
  .ant-select-arrow {
    top: 47%;
    right: 6px;
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  .select {
    width: 57px !important;
  }
  .ant-btn-lg {
    height: 45px !important;
    font-size: 18px !important;
  }
  .product_total {
    font-size: 23px !important;
  }
  .ant-form-item-required {
    font-size: 18px !important;
  }
  .ant-form-item-label > label {
    font-size: 18px;
  }
  .ant-input-lg {
    font-size: 18px !important;
  }
  .city_container {
    height: 43px;
    display: flex;
    justify-content: center;
  }
  .city {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }
  .anticon-check-circle {
    font-size: 18px !important;
    margin-right: 0;
  }
  .anticon-close-circle {
    font-size: 18px !important;
    margin-right: 0;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 16px !important;
    text-align: center;
  }
  .user-info-title {
    font-size: 23px !important;
  }
  .user-info-description {
    font-size: 18px !important;
  }
  .ant-input-affix-wrapper-lg {
    font-size: 18px;
    height: 43px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 1199px) {
  .item_span {
    font-size: 17px;
  }
  .item_span_img {
    font-size: 17px !important;
  }
  .sidebar_item {
    font-size: 17px !important;
    height: 4rem !important;
  }
  .ant-layout-sider {
    flex: 0 0 13rem !important;
    max-width: 13rem !important;
    min-width: 13rem !important;
    width: 13rem !important;
  }
  .ant-list-item-meta-title {
    font-size: 16px !important;
  }
  .ant-form-item-label > label {
    font-size: 16px;
  }
  .product_price {
    font-size: 16px;
    margin-right: 20px;
  }
  .product_count {
    font-size: 16px;
    margin-right: 10px;
  }
  .select-option {
    font-size: 16px !important;
  }
  .ant-select-selection-item {
    font-size: 16px !important;
    padding: 3px 9px 0 0 !important;
  }
  .ant-select-selector {
    height: 32px !important;
  }
  .ant-select-arrow {
    top: 50%;
    right: 4px;
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
  .select {
    width: 57px !important;
  }
  .ant-btn-lg {
    height: 40px !important;
    font-size: 16px !important;
  }
  .product_total {
    font-size: 21px !important;
  }

  .ant-form-item-required {
    font-size: 16px !important;
  }
  .ant-input-lg {
    font-size: 16px !important;
  }
  .anticon-check-circle {
    font-size: 16px !important;
    margin-right: 0;
  }
  .anticon-close-circle {
    font-size: 16px !important;
    margin-right: 0;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 14px !important;
    text-align: center;
    /* max-width: 180px; */
  }
  .product-input-number {
    width: 180px;
  }
  .user-info-title {
    font-size: 21px !important;
  }
  .user-info-description {
    font-size: 16px !important;
  }
  .ant-input-affix-wrapper-lg {
    font-size: 16px;
    height: 40px;
  }
  .ant-form-item {
    margin-bottom: 6px;
  }
}
