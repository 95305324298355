.header {
  height: 8.2rem;
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 0 40px;
}

.logo {
  float: left;
  width: 200px;
  height: 110px;
  margin: 16px 14px 16px 0;
  background: url("../../../constants/logo1.jpg");
  background-color: white;
  background-size: 199px 110px;
}
.container {
  display: flex;
  flex-direction: column;
  height: 8.2rem;
}
.container_photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  height: 8.2rem;
}
.photo {
  margin: 0 10px;
}
.container_menu {
  height: 8.2rem;
}
.menu {
  display: flex;
  justify-content: space-between;
  height: 8.2rem;
}
.item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 8.2rem;
}
.item_span {
  font-size: 30px;
}
.item_span_img {
  font-size: 30px !important;
}
