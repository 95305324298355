.left_sider {
  min-height: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  left: 0;
}

.sidebar_item {
  font-size: 25px;
  height: 5rem !important;
  white-space: normal !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  line-height: normal;
  margin-left: 15px;
}
